<template>
  <div class="swiper">
    <!-- swiper -->
    <swiper :options="swiperOption">
      <swiper-slide v-for="(item, index) in swiperList" :key="index">
        <img :src="item.img" />

        <div class="swiper_footer">
          <div class="swiper_font">{{ item.name }}</div>
        </div>
      </swiper-slide>

      <div class="swiper-pagination my_pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
  props: {
    swiperList: Array,
  },
  data() {
    return {
      index: 0,
      newTranlate: 0,
      timer: '',
      loop: true,
      //swiper 初始化
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true, //允许分页点击跳转
        },
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
@import './swiper.css';
</style>
