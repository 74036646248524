<template>
  <div class="home" ref="home">
    <app-header ref="header" />

    <div class="home_wrapper" ref="homeWrapper">
      <div class="home_max_warp">
        <div class="home_main" v-if="this.$route.path == '/'">
          <section class="home_top">
            <div class="fase_left">
              <app-swiper
                :swiperList="homeObj.banner"
                v-if="homeObj.banner && homeObj.banner.length"
              />
            </div>

            <div class="fase_rig">
              <div class="fase_rig_title">搜索产品</div>

              <div class="fase_rig_line">
                <div class="fase_rig_name">融资额度</div>

                <div class="fase_rig_select">
                  <el-select
                    v-model="value1"
                    placeholder="请选择融资额度"
                    clearable
                    @clear="clearClass(1)"
                  >
                    <el-option
                      v-for="item in options1"
                      :value="item.value"
                      @click.native="setId(1, item)"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="fase_rig_line">
                <div class="fase_rig_name">担保方式</div>

                <div class="fase_rig_select">
                  <el-select
                    v-model="value2"
                    placeholder="请选择担保方式"
                    clearable
                    @clear="clearClass(2)"
                  >
                    <el-option
                      v-for="item in options2"
                      :value="item.cate_name"
                      @click.native="setId(2, item)"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="fase_rig_line">
                <div class="fase_rig_name">产品标签</div>

                <div class="fase_rig_select">
                  <el-select
                    v-model="value3"
                    placeholder="请选择产品标签"
                    clearable
                    @clear="clearClass(3)"
                  >
                    <el-option
                      v-for="item in options3"
                      :value="item.cate_name"
                      @click.native="setId(3, item)"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="fase_rig_btn" @click="searchData">立即搜索产品</div>
            </div>
          </section>

          <section class="home_information">
            <div class="home_information_item">
              <div class="home_information_num">
                <span style="margin-right: 3px;">{{ num3 }}</span
                >家
              </div>
              <div class="home_information_bm">
                <img src="../../assets/image/1.png" class="home_icon" />
                <div class="home_information_font">入驻机构</div>
              </div>
            </div>

            <div class="home_information_item">
              <div class="home_information_num">
                <span style="margin-right: 3px;">{{ num2 }}</span
                >家
              </div>
              <div class="home_information_bm">
                <img src="../../assets/image/5.png" class="home_icon" />
                <div class="home_information_font">入驻企业</div>
              </div>
            </div>

            <div class="home_information_item">
              <div class="home_information_num">
                <span style="margin-right: 3px;">{{ num7 }}</span
                >万元
              </div>
              <div class="home_information_bm">
                <img src="../../assets/image/20.png" class="home_icon" />
                <div class="home_information_font">授信金额</div>
              </div>
            </div>

            <div class="home_information_item">
              <div class="home_information_num">
                <span style="margin-right: 3px;">{{ num6 }}</span
                >万元
              </div>
              <div class="home_information_bm">
                <img src="../../assets/image/19.png" class="home_icon" />
                <div class="home_information_font">申请总金额</div>
              </div>
            </div>

            <div class="home_information_item">
              <div class="home_information_num">
                <span style="margin-right: 3px;">{{ num4 }}</span
                >次
              </div>
              <div class="home_information_bm">
                <img src="../../assets/image/4.png" class="home_icon" />
                <div class="home_information_font">需求发布数</div>
              </div>
            </div>

            <div class="home_information_item">
              <div class="home_information_num">
                <span style="margin-right: 3px;">{{ num1 }}</span
                >个
              </div>
              <div class="home_information_bm">
                <img src="../../assets/image/2.png" class="home_icon" />
                <div class="home_information_font">产品总数量</div>
              </div>
            </div>

            <!-- <div class="home_information_item">
							<div class="home_information_num"><span style="margin-right: 3px;">{{num5}}</span>家</div>
							<div class="home_information_bm">
								<img src="../../assets/image/5.png" class="home_icon" />
								<div class="home_information_font">产品申请数</div>
							</div>
						</div>	 -->
          </section>

          <section class="home_shuffling">
            <div class="home_shuffling_left">
              <div class="home_shuffling_title" style="width: 100%;">
                融资需求
              </div>

              <div class="home_damand">
                <div class="home_damand_table1">
                  <div class="home_damand_tb1">企业名称</div>
                  <div class="home_damand_tb2">所在地区</div>
                  <div class="home_damand_tb3">拟融额度</div>
                  <div class="home_damand_tb4">发布时间</div>
                </div>

                <div
                  class="home_damand_list"
                  ref="damandPos"
                  @mouseenter="stopPlay1"
                  @mouseleave="startPlay1"
                >
                  <div class="home_damand_pos" ref="damandList">
                    <div
                      class="home_damand_table2"
                      v-for="(item, index) in demand_list"
                      :key="index"
                    >
                      <div class="home_damand_tr1">{{ item.company_name }}</div>
                      <div class="home_damand_tr2">{{ item.name }}</div>
                      <div class="home_damand_tr3">{{ item.quota }}万</div>
                      <div class="home_damand_tr4">{{ item.create_time }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="home_shuffling_rig">
              <div class="home_damand_title">最新融资动态</div>

              <div
                class="dynamic"
                ref="shufflingPos"
                @mouseenter="stopPlay2"
                @mouseleave="startPlay2"
              >
                <div class="dynamic_pos" ref="dynamicList">
                  <div
                    class="dynamic_item"
                    v-for="(item, index) in financial_list"
                    :key="index"
                  >
                    <div class="dynamic_item_name">
                      {{ item.name }}<span>受理</span>{{ item.company_name
                      }}{{ item.accept_quota }}万融资需求
                    </div>

                    <div class="dynamic_item_time">{{ item.accept_time }}</div>
                  </div>
                </div>
              </div>

              <div class="dynamic_release" @click="toFinancingneeds">
                立即发布融资需求
              </div>
            </div>
          </section>

          <div class="home_hr"></div>

          <section class="home_product">
            <div class="home_product_top">
              <div class="home_product_title">金融产品</div>
              <div class="home_product_more" @click="toFinancialproducts">
                查看更多>>
              </div>
            </div>
          </section>

          <section class="home_list" v-if="flag == 1">
            <div
              class="home_list_good"
              v-for="(item, index) in listsItem"
              :key="index"
            >
              <div class="good_pic_box">
                <el-image :src="item.gallery" class="good_pic"></el-image>
              </div>

              <div class="home_good_rig" @click="toproductsdetail(item.id)">
                <div class="home_good_top">
                  <div class="home_good_name" :title="item.name">
                    {{ item.name
                    }}<span v-if="item.is_policy == 1">政策型产品</span>
                  </div>

                  <div class="home_good_detail">查看详情</div>
                </div>

                <div class="home_good_bm">
                  <div class="home_good_message">
                    <div class="home_good_label">
                      融资额度：
                      <span>{{ item.min_quota }}~{{ item.max_quota }}万</span>
                    </div>
                    <div class="home_good_label">
                      利率：<span
                        >{{ item.min_rate }}%~{{ item.max_rate }}%</span
                      >
                    </div>
                    <div class="home_good_label">
                      适用地区： <span>{{ item.area_name }}</span>
                    </div>
                    <!-- <div class="home_good_label">抵款期限： <span>{{item.min_credit}}~{{item.max_credit}}月</span></div> -->
                    <div class="home_good_label">
                      担保： <span> {{ item.cate_name }}</span>
                    </div>
                  </div>

                  <div
                    class="home_good_apply"
                    @click.stop="towebapply(item.id)"
                  >
                    立即申请
                  </div>
                </div>
              </div>
            </div>

            <!-- 分页 -->
            <div class="home_list_reportscreen">
              <div class="home_list_footer">
                <div class="home_list_mar">
                  <app-mypaginations
                    :pagSize="paginations"
                    :allData="goodList"
                    @setHandleCurrent="handleCurrent"
                    @setHandleSize="handleSize"
                    @setPage="setPage"
                    @setSize="setSize"
                  />
                </div>
              </div>
            </div>
          </section>

          <el-empty
            description="数据为空"
            v-if="flag == 0"
            :image-size="200"
          ></el-empty>

          <section class="home_message">
            <div class="home_message_left">
              <div class="home_noticeannounce_title">
                <div class="home_noticeannounce_name">文件通知</div>
                <div class="home_noticeannounce_more" @click="toNoticeannounce">
                  查看更多>>
                </div>
              </div>

              <div class="home_noticeannounce_list">
                <div
                  class="home_noticeannounce_item"
                  v-for="(item, index) in noticeList"
                  :key="index"
                  @click="toNoticedetails(item.id)"
                >
                  <div class="home_noticeannounce_message" :title="item.title">
                    {{ item.title }}
                  </div>
                  <div class="home_noticeannounce_time">
                    {{ item.create_time }}
                  </div>
                </div>
              </div>
            </div>

            <div class="home_message_rig">
              <div class="home_policyissued_title">
                <div class="home_policyissued_name">工作动态</div>
                <div class="home_policyissued_more" @click="toPolicyissued">
                  查看更多>>
                </div>
              </div>

              <div class="home_policyissued_list">
                <div
                  class="home_policyissued_item"
                  v-for="(item, index) in policyList"
                  :key="index"
                  @click="toPolicydetails(item.id)"
                >
                  <div class="home_policyissued_message" :title="item.title">
                    {{ item.title }}
                  </div>
                  <div class="home_policyissued_time">
                    {{ item.create_time }}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="home_cooperation">
            <div class="home_cooperation_top">
              <div class="home_cooperation_title">合作机构</div>
              <div
                class="home_cooperation_more"
                @click="toFinancialinstitutions"
              >
                查看更多>>
              </div>
            </div>

            <div class="home_cooperation_list">
              <div class="home_cooperation_logo">
                <div
                  class="home_cooperation_item"
                  v-for="(item, index) in finance_list"
                  :key="index"
                  @click="toagencydetail(item.id)"
                >
                  <div class="home_cooperation_box">
                    <el-image
                      :src="item.finance_trade"
                      class="cooperation_pic"
                    ></el-image>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <router-view />
      </div>
    </div>

    <app-footer ref="footer" />
  </div>
</template>

<script>
import Header from '@/components/Header/header.vue';
import Footer from '@/components/Footer/footer.vue';
import My_Swpper from '@/components/Swiper/swiper.vue';
import Swiper from 'swiper';
import MyPaginations from '@/components/MyPaginations/mypaginations.vue';

export default {
  data() {
    return {
      homeObj: {},
      demand_list: [],
      financial_list: [
        {
          name: '工商银行青海分行',
          company_name: '青海中钛青锻装备制造有限公司',
          accept_quota: '8000',
          accept_time: '2021.05.09',
        },
        {
          name: '光大银行西宁分行',
          company_name: '青海中钛青锻装备制造有限公司',
          accept_quota: '3000',
          accept_time: '2021.09.10',
        },
        {
          name: '中信银行西宁分行',
          company_name: '青海鑫邦线缆有限公司',
          accept_quota: '500',
          accept_time: '2021.10.29',
        },
        {
          name: '工商银行青海分行',
          company_name: '青海鑫邦线缆有限公司',
          accept_quota: '300',
          accept_time: '2021.02.21',
        },
        {
          name: '建设银行青海分行',
          company_name: '青海鑫邦线缆有限公司',
          accept_quota: '100',
          accept_time: '2021.08.29',
        },
        {
          name: '中信银行西宁分行',
          company_name: '青海恩泽农业技术有限公司',
          accept_quota: '500',
          accept_time: '2021.07.03',
        },
        {
          name: '光大银行西宁分行',
          company_name: '青海新丁香粮油有限责任公司',
          accept_quota: '1000',
          accept_time: '2021.05.29',
        },
        {
          name: '中信银行西宁分行',
          company_name: '欧耐特线缆集团有限公司',
          accept_quota: '500',
          accept_time: '2021.06.18',
        },
        {
          name: '建设银行青海分行',
          company_name: '海东市旭格光电科技有限公司',
          accept_quota: '500',
          accept_time: '2021.01.11',
        },
        {
          name: '光大银行西宁分行',
          company_name: '青海诺德新材料有限公司',
          accept_quota: '5000',
          accept_time: '2021.09.23',
        },
      ],
      product: [],
      channel: [],
      guarantee: [],
      finance_list: [],
      labels: [],
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: 'total, sizes, prev, pager, next, jumper', // 翻页属性
      },
      value1: '',
      value2: '',
      value3: '',
      value4: -1,
      value5: -1,
      value6: -1,
      value7: -1,
      value8: -1,
      value9: -1,
      value10: -1,
      value11: -1,
      value12: -1,
      num1: 0,
      num2: 0,
      num3: 0,
      num4: 0,
      num5: 0,
      num6: 0,
      num7: 0,
      options1: [
        {
          value: '0~100万',
          id: 1,
        },
        {
          value: '100~500万',
          id: 2,
        },
        {
          value: '500万以上',
          id: 3,
        },
      ],
      options2: [],
      options3: [],
      quCity: [],
      type1: '',
      type2: '',
      type3: '',
      goodList: [],
      listsItem: [],
      adopt: -1,
      nowTime: 0,
      lastTime: 0,
      token: localStorage.eleToken,
      flag: 1,
      timer1: '',
      timerout1: '',
      timer2: '',
      interTime2: '',
      speed: 0,
      speed1: 0,
      noticeList: [],
      policyList: [],
      loading: true,
    };
  },
  components: {
    'app-header': Header,
    'app-footer': Footer,
    'app-swiper': My_Swpper,
    'app-mypaginations': MyPaginations,
  },
  methods: {
    toNoticeannounce() {
      this.$router.push('/noticeannounce');
    },
    toPolicyissued() {
      this.$router.push('/policyissued');
    },
    toNoticedetails(id) {
      this.$router.push({ path: '/noticedetails', query: { ids: id } });
    },
    toPolicydetails(id) {
      this.$router.push({ path: '/policydetails', query: { ids: id } });
    },
    // 最新融资动态列表 滚动
    scrollFinancialList(scrollT) {
      let _this = this;
      if (this.financial_list.length > 0) {
        let lovHeight = this.$refs.shufflingPos.offsetHeight;
        let lheight = document.getElementsByClassName('dynamic_item')[0]
          .offsetHeight;
        let alheight = lheight * this.financial_list.length;

        if (alheight > lovHeight) {
          let mathHeight = alheight - lovHeight;
          this.speed1 = mathHeight / alheight;
          this.$refs.dynamicList.style.transition = `all ${this.financial_list
            .length / 5.5}s linear`;
          let numb = scrollT;

          this.timer2 = setInterval(() => {
            numb -= lheight;

            this.$refs.dynamicList.style.top = numb + 'px';

            if (-numb >= mathHeight) {
              clearInterval(this.timer2);

              if (this.timerout2) {
                clearTimeout(this.timerout2);
              }

              this.$refs.dynamicList.style.transition = `all 0s linear`;
              this.timerout2 = setTimeout(() => {
                this.$refs.dynamicList.style.top = 0 + 'px';

                this.scrollFinancialList(0);
              }, 3000);
            }
          }, 2000);
        }
      }
    },
    // 融资需求列表 滚动
    scrollDemandList(scrollT) {
      let _this = this;

      if (this.demand_list.length > 0) {
        let ovHeight = this.$refs.damandPos.offsetHeight;
        let height = document.getElementsByClassName('home_damand_table2')[0]
          .offsetHeight;
        let heights = height * this.demand_list.length;

        if (heights > ovHeight) {
          let mathHeight = heights - ovHeight;
          this.speed = mathHeight / heights;
          this.$refs.damandList.style.transition = `all ${this.demand_list
            .length / 3.5}s linear`;
          let numb = scrollT;

          this.timer1 = setInterval(() => {
            numb -= height;

            this.$refs.damandList.style.top = numb + 'px';

            if (-numb >= mathHeight) {
              clearInterval(this.timer1);

              if (this.timerout1) {
                clearTimeout(this.timerout1);
              }

              this.timerout1 = setTimeout(() => {
                this.$refs.damandList.style.top = 0 + 'px';
                this.$refs.damandList.style.transition = `all 0s linear`;
                this.scrollDemandList(0);
              }, 3000);
            }
          }, 2000);
        }
      }
    },
    startPlay1() {
      let top = this.$refs.damandList.style.top.split('px');
      this.scrollDemandList(Number(top[0]));
    },
    stopPlay1() {
      clearInterval(this.timer1);
      if (this.timerout1) {
        clearTimeout(this.timerout1);
      }

      this.$refs.damandList.style.transition = 'all 0s linear';
    },
    startPlay2() {
      let top = this.$refs.dynamicList.style.top.split('px');
      this.scrollFinancialList(Number(top[0]));
    },
    stopPlay2() {
      clearInterval(this.timer2);
      if (this.timerout2) {
        clearTimeout(this.timerout2);
      }

      this.$refs.dynamicList.style.transition = 'all 0s linear';
    },
    searchData() {
      let data = {
        a: this.type1,
        b: this.type2,
        c: this.type3,
      };

      this.$router.push({
        path: '/financialproducts',
        query: { data: JSON.stringify(data) },
      });
    },
    setId(num, item) {
      if (num == 1) {
        this.type1 = item.id;
      }

      if (num == 2) {
        this.type2 = item.id;
      }

      if (num == 3) {
        this.type3 = item.id;
      }
    },
    toagencydetail(id) {
      this.$router.push({ path: '/agencydetails', query: { ids: id } });
    },
    toFinancialproducts() {
      this.$router.push('/financialproducts');
    },
    toFinancialinstitutions() {
      this.$router.push('/financialinstitutions');
    },
    // 用户信息
    getVali() {
      let data = { token: localStorage.eleToken ? localStorage.eleToken : '' };

      this.$post('/vali', data).then((res) => {
        if (res.data.status == 1) {
          this.adopt = res.data.result.adopt;

          if (res.data.result.type == 1) {
            if (this.adopt == 0) {
              this.$message({
                message: '实名认证审核中',
                type: 'info',
              });
            } else if (this.adopt == 1) {
              this.$router.push('/financingneeds');
            } else if (res.data.result.adopt == 2) {
              this.$confirm('实名认证不通过，是否重新提交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  //   this.$router.push('/enterprisecertification')
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else if (res.data.result.adopt == 3) {
              this.$confirm('还没有实名认证，是否前去完善？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  //   this.$router.push('/enterprisecertification')
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else {
              return false;
            }
          } else {
            // localStorage.clear()

            // this.$router.replace('/login')
            this.$message({
              message: '金融机构不能发布融资需求',
              type: 'error',
            });
          }
        }
      });
    },
    toFinancingneeds() {
      if (this.token) {
        this.nowTime = new Date();

        if (this.nowTime - this.lastTime > 1500) {
          this.getVali();
          this.lastTime = this.nowTime;
        }
      } else {
        this.$$jump(location.href);
      }
    },
    towebapply(item) {
      this.nowTime = new Date();

      if (this.nowTime - this.lastTime > 1500) {
        this.getValiweb(item);

        this.lastTime = this.nowTime;
      }
    },
    toproductsdetail(id) {
      this.$router.push({
        path: '/productsdetail',
        query: { ids: id, type: 1 },
      });
    },
    clearClass(num) {
      if (num == 1) {
        this.type1 = '';
      }

      if (num == 2) {
        this.type2 = '';
      }

      if (num == 3) {
        this.type3 = '';
      }
    },
    setPage(data) {
      this.paginations.page_index = data;

      this.getList();
    },
    setSize(data) {
      this.paginations.page_index = 1;
      this.paginations.page_size = data;

      this.getList();
    },
    handleCurrent(item) {
      this.listsItem = item;
    },
    handleSize(item) {
      this.listsItem = item;
    },
    //重置分页
    resetPaginations() {
      // 总页数
      this.paginations.total = this.count;
      // 设置默认分页数据
      this.listsItem = this.goodList.filter((item, index) => {
        return index < this.paginations.page_size;
      });
    },
    // 用户信息
    getValiweb(item) {
      let data = { token: localStorage.eleToken ? localStorage.eleToken : '' };

      this.$post('/vali', data).then((res) => {
        if (res.data.status == 1) {
          this.adopt = res.data.result.adopt;

          if (res.data.result.type == 1) {
            if (this.adopt == 0) {
              this.$message({
                message: '实名认证审核中',
                type: 'info',
              });
            } else if (this.adopt == 1) {
              this.$router.push({
                path: '/webproductapply',
                query: { ids: item, type: 1 },
              });
            } else if (res.data.result.adopt == 2) {
              this.$confirm('实名认证不通过，是否重新提交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  //   this.$router.push('/enterprisecertification')
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else if (res.data.result.adopt == 3) {
              this.$confirm('还没有实名认证，是否前去完善？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  //   this.$router.push('/enterprisecertification')
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else {
              return false;
            }
          }

          if (res.data.result.type == 2) {
            // localStorage.clear()
            // this.$router.replace('/login')
            this.$message({
              message: '金融机构不能申请产品',
              type: 'error',
            });
          }
        }
      });
    },
    // 首页数据
    getData() {
      let data = {};
      this.demand_list = [];
      this.finance_list = [];

      this.$post('index_list', data).then((res) => {
        if (res.data.status == 1) {
          let p = new Promise((resolve, reject) => {
            this.homeObj = res.data.result;

            this.num1 = this.homeObj.count[0].cnt
              ? this.homeObj.count[0].cnt
              : 0;
            this.num2 = this.homeObj.count[1].cnt
              ? this.homeObj.count[1].cnt
              : 0;
            this.num3 = this.homeObj.count[2].cnt
              ? this.homeObj.count[2].cnt
              : 0;
            this.num4 = this.homeObj.count[3].cnt
              ? this.homeObj.count[3].cnt
              : 0;
            this.num5 = this.homeObj.count[4].cnt
              ? this.homeObj.count[4].cnt
              : 0;
            this.num6 = this.homeObj.count[5].cnt
              ? this.homeObj.count[5].cnt
              : 0;
            this.num7 = this.homeObj.count[6].cnt
              ? this.homeObj.count[6].cnt
              : 0;

            this.demand_list = !res.data.result.demand_list
              ? []
              : res.data.result.demand_list;
            this.financial_list = !res.data.result.financial_list
              ? this.financial_list
              : res.data.result.financial_list.concat(this.financial_list);
            this.financial_list.map((item) => {
              if (item.company_name.length > 5) {
                item.company_name = '*****' + item.company_name.substring(5);
              } else {
                item.company_name = '*****';
              }
            });

            this.finance_list = !res.data.result.finance_list
              ? []
              : res.data.result.finance_list;

            resolve();
          });

          p.then(() => {
            // 融资需求列表  home_damand_table2
            this.scrollDemandList(0);

            // 最新融资动态列表
            this.scrollFinancialList(0);
          });
        }
      });
    },
    // 适用地区
    getQhCityList() {
      let data = {};

      this.$post('/qh_city_lst', data).then((res) => {
        this.quCity = res.data.result ? res.data.result : [];
      });
    },
    // 分类   1:产品分类  2：担保  3： 渠道  4：标签
    getClass1() {
      this.$get('/pro_cate', { type: 1 }).then((res) => {
        if (res.data.status == 1) {
          this.product = !res.data.result ? [] : res.data.result;

          this.options3 = !res.data.result ? [] : res.data.result;
        }
      });
    },
    getClass2() {
      this.$get('/pro_cate', { type: 3 }).then((res) => {
        this.channel = !res.data.result ? [] : res.data.result;
      });
    },
    getClass3() {
      this.$get('/pro_cate', { type: 2 }).then((res) => {
        this.guarantee = !res.data.result ? [] : res.data.result;

        this.options2 = !res.data.result ? [] : res.data.result;
      });
    },
    getClass4() {
      this.$get('/pro_cate', { type: 4 }).then((res) => {
        this.labels = !res.data.result ? [] : res.data.result;
      });
    },
    changeSelect() {
      this.paginations.page_index = 1;
      this.getList();
    },
    // 产品列表
    getList() {
      let data = {
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        region_id: this.value4 == -1 ? '' : this.value4,
        cate_id: this.value5 == -1 ? '' : this.value5,
        guarantee_id: this.value6 == -1 ? '' : this.value6,
        channel_id: this.value7 == -1 ? '' : this.value7,
        label_id: this.value8 == -1 ? '' : this.value8,
        quota: this.value10 == -1 ? '' : this.value10,
        term: this.value9 == -1 ? '' : this.value9,
        is_policy: this.value11 == -1 ? '' : this.value11,
        req_num: this.value12 == -1 ? '' : this.value12,
      };

      this.$post('/product_list', data).then((res) => {
        if (!res.data.result) {
          this.goodList = [];
          this.count = 0;

          this.flag = 0;
        } else {
          this.goodList = res.data.result.length == 0 ? [] : res.data.result;
          this.count = res.data.count;

          this.flag = 1;
        }

        this.resetPaginations();
      });
    },
    // 通知公告
    getNotice() {
      let data = {
        page: 1,
        limit: 10,
        keyword: '',
        type: 2,
      };

      this.$get('/news_list', data).then((res) => {
        if (!res.data.result) {
          this.noticeList = [];
        } else {
          this.noticeList = res.data.result.length == 0 ? [] : res.data.result;
        }
      });
    },
    // 政策发布
    getPolicy() {
      let data = {
        page: 1,
        limit: 10,
        keyword: '',
        type: 3,
      };

      this.$get('/news_list', data).then((res) => {
        if (!res.data.result) {
          this.policyList = [];
        } else {
          this.policyList = res.data.result.length == 0 ? [] : res.data.result;
        }
      });
    },
    init() {
      let _this = this;

      if (this.$route.path == '/') {
        this.value1 = '';
        this.value2 = '';
        this.value3 = '';

        this.type1 = '';
        this.type2 = '';
        this.type3 = '';

        this.getData();
        this.getClass1();
        this.getClass2();
        this.getClass3();
        this.getClass4();
        this.getList();
        this.getQhCityList();
        this.getNotice();
        this.getPolicy();
      }
    },
  },
  mounted() {
    // this.$get('http://192.168.0.101:8090/ssoClientOne/test', {ticket: localStorage.eleToken}).then(res => {})
    this.init();
  },
  watch: {
    $route: {
      handler(route) {
        this.$nextTick(() => {
          this.init();
        });
      },
    },
  },
};
</script>

<style scoped>
@import './home.css';
</style>
