<template>
	<footer>
		<div class="footer min_width">
			<div class="flex align_start jus_content padb_30" style="border-bottom: 1px solid rgba(255,255,255,0.3);">
				<div class="footer_logo">
					<img src="../../assets/image/footer_logo.png" class="footer_logo_pic" />
				</div>
				
				<div class="width_21">
					<div class="footer_title">联系我们</div>
					
					<div class="mart_20">
						<div class="footer_font">前台总机：{{setting.front_desk_switchboard}}</div>
						<div class="footer_font">传真号码：{{setting.fax_number}}</div>
						<div class="footer_font">电子邮箱：{{setting.email}}</div>
					</div>
				</div>
				
				<div class="width_21">
					<div class="footer_title">服务热线</div>
					
					<div class="mart_20">
						<div class="flex align_center">
							<div class="footer_bold">{{setting.service_hotline}}</div>
							<span class="footer_time">服务时间：{{setting.service_hours}}</span>
						</div>
						
						<div class="flex align_center mart_10">
							<div class="footer_kefu flex align_center">
								<i class="el-icon-chat-line-round footer_icon"></i>
								<div style="font-size: 8px;">客服中心</div>
							</div>
							
							<div class="footer_time">QQ群号：{{setting.qq_number}}</div>
						</div>
					</div>
				</div>
				
				<div class="width_21">
					<div class="footer_title">关注公众号</div>
					
					<div class="mart_20 flex align_center">
						<div class="footer_code">
							<img src="../../assets/image/code.jpg" class="footer_url_code" />
						</div>
						
						<div style="width: calc(100% - 112px); margin-left: 12px;">
							<div class="footer_code_font">码上有惊喜！</div>
							<div class="footer_mfont">扫描二维码</div>
							<div class="footer_mfont">关注官方微信公众号</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="padt_30 flex align_center jus_content">
				<div class="width_21 footer_zfont">主管单位：{{setting.company}}</div>
				<div class="width_21 footer_zfont">运营单位：{{setting.operating_unit}}</div>
				<div class="width_21 footer_zfont">Copyright（C）2010-{{year}} www.smeqh.cn</div>
				<a href="https://beian.miit.gov.cn/" target="_blank" class="width_21 footer_zfont" @click="toRecordurl">备案号：{{setting.record_no}}</a>
			</div>
		</div>
		
	</footer>
</template>

<script>
	import axios from 'axios'
	
	export default {
		data() {
			return {
				setting: {},
				year: ''
			}
		},
		methods: {
			getSetting() {
				axios({
					baseURL: 'http://www.smeqh.cn/v1',
					url: '/setting',
					method: 'post',
					data: {},
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(res => {
					this.setting = res.data.result
				})
			},
			toRecordurl() {
				if(!this.setting.record_url) {
					window.open(this.setting.record_url, '_blank')
				}
			}
		},
		mounted() {
			this.year = new Date().getFullYear()
			
			this.getSetting()
		}
	}
</script>

<style scoped>
	@import './footer.css';
</style>