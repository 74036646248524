<template>
  <header>
    <div class="header_top">
      <div class="header_content">
        <div class="header_content_left">
          <div class="header_title">青海省工业小微企业融资服务平台</div>

          <div class="header_english">
            Qinghai Small and micro industrial enterprises financing service
            platform
          </div>
        </div>

        <div class="header_content_rig" v-if="!token">
          <div class="header_login" @click="toLoginVue">
            <img src="../../assets/image/3.png" class="header_login_pic" />
            <div class="header_login_font">企业登录</div>
          </div>
          <div class="header_login" @click="toResigter">
            <img src="../../assets/image/21.png" class="header_login_pic" />
            <div class="header_login_font">机构登录</div>
          </div>
        </div>

        <div class="header_content_rig" v-if="token">
          <div class="header_login" @click="toPersonal">
            <img src="../../assets/image/3.png" class="header_login_pic" />

            <el-dropdown
              trigger="hover"
              placement="bottom"
              v-if="token && type == 1"
            >
              <div class="header_login_font">企业个人中心</div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="toUserexplain"
                  >用户说明</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown trigger="hover" placement="bottom" v-else>
              <div class="header_login_font">机构个人中心</div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="toUserexplain"
                  >用户说明</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="header_login" @click="loggin">
            <div class="header_login_font">退出</div>
          </div>
        </div>
      </div>
    </div>

    <nav>
      <div class="nav_list">
        <div
          class="nav_item"
          v-for="(item, index) in navList"
          @click="switchPage(index)"
          :key="index"
          :style="{
            backgroundColor: item.path.includes($route.path) ? '#2C6BE1' : '',
          }"
        >
          <!-- {{ item.path }} -->
          {{ item.name }}
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      ticket: {},
      point: 0,
      token: localStorage.eleToken,

      //token: localStorage.eleToken,
      navList: [
        {
          name: '首页',
          path: [
            '/',
            '/productsdetail',
            '/noticedetails',
            '/policydetails',
            '/policyissued',
          ],
        },
        {
          name: '金融产品',
          path: ['/financialproducts'],
        },
        {
          name: '金融机构',
          path: ['/financialinstitutions', '/agencydetails'],
        },
        {
          name: '融资信息',
          path: [
            '/financeinformation',
            '/informationdetail',
            '/informationdetail',
          ],
        },
        {
          name: '融资需求专区',
          path: ['/financingneeds'],
        },
      ],
      user: '',
      adopt: -1,
      nowTime: 0,
      lastTime: 0,
      type: localStorage.type,
    };
  },
  created() {},
  methods: {
    toUserexplain() {
      this.$router.push('/userexplain');
    },
    // 用户信息
    getVali(index) {
      let data = { token: localStorage.eleToken ? localStorage.eleToken : '' };

      this.$post('/vali', data).then((res) => {
        if (res.data.status == 1) {
          this.adopt = res.data.result.adopt;
          if (res.data.result.type == 1) {
            if (this.adopt == 0) {
              this.$message({
                message: '实名认证审核中',
                type: 'info',
              });
            } else if (this.adopt == 1) {
              this.$router.push('/financingneeds');
              // location.href = 'http://sso.smeqh.cn:9000/realname/index'
              this.point = index;
            } else if (res.data.result.adopt == 2) {
              this.$confirm('实名认证不通过，是否重新提交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  //   this.$router.push({ path: "/enterprisecertification" });
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else if (res.data.result.adopt == 3) {
              this.$confirm('还没有实名认证，是否前去完善？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  //   this.$router.push("/enterprisecertification");

                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else {
              return false;
            }
          }

          if (res.data.result.type == 2) {
            // localStorage.clear()

            // this.$router.replace('/login')
            this.$message({
              message: '金融机构不能发布融资需求',
              type: 'error',
            });
          }
        }
      });
    },

    toLoginVue() {
      console.log('s');
      this.$$jump(location.href);
    },
    toResigter() {
		// this.$$jump(location.href);
      this.$router.push('/login');
    },
    toPersonal() {
      let data = { token: localStorage.eleToken ? localStorage.eleToken : '' };
      this.$post('/vali', data).then((res) => {
        // 用户信息
        if (res.data.status == 1) {
          this.user = res.data.result.type;

          if (this.user == 1) {
            const PersonalCenter = this.$router.resolve('/PersonalCenter');
            window.open(PersonalCenter.href, '_blank');
          }

          if (this.user == 2) {
            const InstitutionalCenter = this.$router.resolve(
              '/InstitutionalCenter'
            );
            window.open(InstitutionalCenter.href, '_blank');
          }
        }
      });
    },
    switchPage(index) {
      const currentPath = this.navList[index].path[0];

      console.log(currentPath);
      if (+index === 4) {
        this.nowTime = new Date();
        if (this.nowTime - this.lastTime > 1500) {
          if (this.token) {
            this.getVali(index);
          } else {
            this.$$jump(location.href);
            // this.$router.replace('/login');
          }

          this.lastTime = this.nowTime;
        }
      } else {
        this.$router.push(currentPath);
      }
      this.point = index;
    },
    // 退出
    loggin() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$get('/logout?token=' + this.token).then((res) => {
            console.log(res);
            this.$message({
              type: 'success',
              message: '已退出',
            });
          });
          localStorage.clear();
          location.reload();
          // this.$router.replace('/login')
          // if(this.$route.path == '/') {
          // 	this.$router.go(0)
          // }else {
          // 	this.$router.replace('/')
          // }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    watchRouter() {
      if (this.$route.path == '/') {
        this.point = 0;
      } else if (this.$route.path == '/financialproducts') {
        this.point = 1;
      } else if (
        this.$route.path == '/financialinstitutions' ||
        this.$route.path == '/agencydetails'
      ) {
        this.point = 2;
      } else if (
        this.$route.path == '/financeinformation' ||
        this.$route.path == '/informationdetail'
      ) {
        this.point = 3;
      } else if (this.$route.path == '/financingneeds') {
        this.point = 4;
      } else if (
        this.$route.path == '/productsdetail' ||
        this.$route.path == '/webproductapply'
      ) {
        if (this.$route.query.type == 1) {
          this.point = 0;
        }

        if (this.$route.query.type == 2) {
          this.point = 1;
        }

        if (this.$route.query.type == 3) {
          this.point = 2;
        }
      } else if (
        this.$route.path == '/noticeannounce' ||
        this.$route.path == '/policyissued' ||
        this.$route.path == '/noticedetails' ||
        this.$route.path == '/policydetails' ||
        this.$route.path == '/userexplain'
      ) {
        this.point = -1;
      }

      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
  },
  mounted() {
    //
    let href = decodeURIComponent(location.href);
    let backhref = href.substring(href.indexOf('back='));
    backhref = backhref.substring(backhref.indexOf('http'));

    console.log('backhref：', backhref);

    console.log('ticket：', this.$route.query.ticket);
    if (this.$route.query.ticket) {
      // document.cookie = `qhs-sso=${encodeURI(this.$route.query.ticket)}`
      const ticket = this.$route.query.ticket;

      // this.baseUrl = 'http://zk.zhenchangkj.com/v1';
      // 设置加载效果
      const loading = this.$loading({
        lock: true,
        text: '正在登录...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$get('/sso_login', { ticket: ticket })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            // 关闭加载效果
            loading.close();
            localStorage.setItem('eleToken', res.data.data);
            localStorage.setItem('type', 1);

            // location.href = decodeURIComponent(this.$route.query.back);
            backhref = backhref.substring(
              backhref.indexOf('http'),
              backhref.indexOf('&ticket')
            );

            console.log('backhref1：', backhref);
            location.href = backhref;
            //  this.openFullScreen2();
          } else {
            alert(res.msg);

            setTimeout(function() {
              this.$$jump(location.href);
            }, 800);
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.type = localStorage.type ? localStorage.type : '';
        this.token = localStorage.eleToken ? localStorage.eleToken : '';

        this.watchRouter();

        console.log(route.path, this.point);
      },
    },
  },
};
</script>

<style scoped>
@import './header.css';
</style>
